import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { StaticImage } from 'gatsby-plugin-image';

const About = () => {
    return (
        <Layout>
            <SEO
                title="About Glendale Builders"
                description="Jeff and Robert have run Glendale Builders for years with pride and professionalism. Our core values have always guided our business and earn our clients respect."
            />
            <section className="main-about" aria-label="About Glendale Builders">
                <StaticImage src="../images/IMG_0769-a.jpg" className="main-about__banner" alt="" layout="fullWidth" quality={100} objectPosition="0 20%" />
                <div className="main-about__container">
                    <div className="main-about__biographies">
                        <section className="main-about__biography" aria-label="Biography of Jeffrey Cernek">
                            <div className="main-about__asset">
                                <h2 className="main-about__name">
                                    Jeffrey T. Cernek
                                </h2>
                                <StaticImage src="../images/IMG_0743.jpg" className="main-about__image" alt="" quality={100} />
                            </div>
                            <div className="main-about__content">
                                <p className="main-about__paragraph">
                                    Jeffrey Cernek, co-owner and managing member of Glendale Builders, has spent a lifetime in the
                                    construction and building industries. Beginning in 1977 as a masonry laborer, then as a
                                    carpenter, he worked his way up in various companies and into the main office as a project
                                    estimator for a national commercial general contractor.
                                </p>
                                <p className="main-about__paragraph">
                                    He started Glendale Builders LLC with Robert in 2004, building elegant custom homes 
                                    for sale to well-heeled clientele. During the recession of 2008, the company transitioned 
                                    to a more varied approach to the construction field, getting involved in home improvement, 
                                    repair and remodeling, while still building homes for discriminating homeowners.
                                </p>
                                <p className="main-about__paragraph">
                                    He is married with 3 children, is a life-long resident of Glenview, IL and has been an active 
                                    member of the community serving as a baseball, soccer and basketball coach, a local church 
                                    board member, a Sunday school teacher, and the equipment director for the Glenbrook South 
                                    High School Instrumental League for 9 years.
                                </p>
                            </div>
                        </section>
                        <section className="main-about__biography main-about__biography--reverse" aria-label="Biography of Robert Wojslaw">
                            <div className="main-about__asset main-about__asset--reverse">
                                <h2 className="main-about__name">
                                    Robert Wojslaw
                                </h2>
                                <StaticImage src="../images/IMG_0756.jpg" className="main-about__image" alt="" quality={100} />
                            </div>
                            <div className="main-about__content">
                                <p className="main-about__paragraph">
                                    Robert Wojslaw, co-owner of Glendale Builders, has over 30 years of experience in the
                                    construction industry. In 1989, Robert immigrated from Poland to the United States, where he
                                    immediately began working for his brother-in-law as a subcontractor.
                                </p>
                                <p className="main-about__paragraph">
                                    He has been a part of every element imaginable in the home building and renovation process. 
                                    After working for 13 years as a subcontractor, he quickly found himself in the role of a 
                                    general contractor when he started Glendale Builders LLC with Jeff in 2004. Robert is 
                                    married with 2 children and is a long-time resident of Glenview, IL.
                                </p>
                            </div>
                        </section>
                    </div>
                    <section className="main-about__values" aria-label="Glendale Builders Core Values">
                        <h2 className="main-about__title">
                            Why Choose Glendale Builders?
                        </h2>
                        <div className="main-about__wrapper">
                            <p className="main-about__pride">
                                At Glendale Builders, we take pride in our work with every job we do.
                                Our <strong className="main-about__core">Core Values</strong> are very
                                important to us and are what makes the difference between good work and 
                                great work.
                            </p>
                            <p className="main-about__include">
                                Some of Our Core Values Include:
                            </p>
                            <ul className="main-about__list">
                                <li className="main-about__item">
                                    Professionalism
                                </li>
                                <li className="main-about__item">
                                    Fairness
                                </li>
                                <li className="main-about__item">
                                    Honesty
                                </li>
                                <li className="main-about__item">
                                    Punctuality
                                </li>
                                <li className="main-about__item">
                                    Quality Service
                                </li>
                            </ul>
                            <p className="main-about__closing">
                                Give us a call or email us today and learn what it's like to be apart 
                                of an expert team. Together, we can get you into the home you've 
                                always dreamed of having.
                            </p>
                        </div>
                    </section>
                </div>
            </section>
        </Layout>
    );
};

export default About;
